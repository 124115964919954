body {
  margin: 0;
  font-family: "Francois One", sans-serif;
  background: center center fixed;
  background-image: linear-gradient(to top, black, transparent),
    url("./components/images/backgroundflightplanhorizontal.jpeg");

  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
}

@media (orientation: portrait) {
  h1 {
    color: #f7fbff;
    font-size: 8em;
    margin-bottom: 0;
  }
}

@media (orientation: landscape) {
  h1 {
    color: #f7fbff;
    font-size: 4.5em;
    margin-bottom: 0;
  }
}

a {
  text-decoration: none;
}

.home {
  margin: 0 auto;
  /* border: 1px solid #ffffff; */
}

.layout {
  margin-top: 1.5em;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  max-width: 700px;
}

.layout button {
  width: 9em;
  height: 4.5em;
  margin: 0.5em 0.5em;
  font-size: 1.6em;
  font-family: inherit;
  background-color: #f7fbff;
  border: 0;
  border-radius: 5px;
  padding: 0.5em 1em;
  border-top: 0.3em solid red;
  text-align: left;
  color: black !important;
}

@media (orientation: portrait) {
  .layout button {
    font-size: 3em;
  }
}

.layout button:hover,
.layout .button:hover {
  background-color: #f7fbff;
  border-top: 0.3em solid #f7fbff;
  color: red;
  cursor: pointer;
}

.layout svg {
  display: block;
  color: red;
  margin: 0;
}

.nav-logo {
  background: none;
  border: none;
  color: #f7fbff;
  padding: 0.5em 3em;
  width: 100%;
  cursor: pointer;
}

.linkedin-logo {
  background: none;
  border: none;
  width: 5%;
  cursor: pointer;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2em;
  width: 30em;
}

.nav-buttons button {
  background: none;
  border: none;
  color: #f7fbff;
  font-size: 1.5em;
  font-family: "Francois One", sans-serif;
  opacity: 0.8;
  width: 4.9em;
}

.nav-buttons .active-page-btn,
.nav-buttons .active-page-btn:hover {
  color: #0077cc;
  cursor: pointer;
}

.btn-mb {
  width: 5.2em !important;
}

.nav-buttons button:hover {
  color: #04080c;
  cursor: pointer;
  opacity: 1;
}

.main-container {
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  text-shadow: -1px -1px 20px #000;
}

.select-model-container {
  padding: 2em;
  height: fit-content;
}

.select-model-container label {
  display: inline-block;
  padding: 0.5em 0.5em 0 0.05em;
}
.select-model-container select {
  border: none;
  border-radius: 8px;
  padding: 0 0.5em;
  font-family: inherit;
}
.select-model-container button {
  border: none;
  border-radius: 8px;
  padding: 0 0.5em;
  font-family: inherit;
}

.map-container,
.mb-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1em;
}

.map-table {
  height: 40em;
  padding-right: 2em;
  padding-top: 1em;
}

.map-table td,
.mb-table td {
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.map-table input,
.mb-table input {
  width: 5em;
  margin: 0 1em;
}

.boton-generar-pdf {
  display: block;
  padding: 0.5em 1em;
  margin: 1em 4em;
  border: none;
  background-color: #0077cc;
  color: #ffffff;
  border-radius: 5px;
}

.boton-generar-pdf:hover {
  background-color: #ffffff;
  color: #0077cc;
  cursor: pointer;
}

.boton-generar-pdf:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.nav-container .boton-generar-pdf {
  margin: 0;
  font-size: 1.2em;
  font-weight: bold;
}

.points-table,
.mb-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 2em 0;
  width: max-content;
  margin: 1em auto;
}

.mb-table {
  margin-left: 5em;
}
.points-table input {
  padding: 0.5em;
  width: 9em;
  margin: 0.3em;
}

.points-table-labels,
.mb-table-labels {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-around;
}

.points-table-labels li {
  width: 7em;
  text-align: center;
  display: inline-block;
}

input {
  text-align: center;
  font-family: inherit;
  font-style: italic;
  border-radius: 5px;
}

.graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
}

.graph-container button {
  margin: 1em 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: center;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.meteo-container {
  background: linear-gradient(black, transparent);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  width: 80%;
  margin: 0 auto;
  border-radius: 20px;
}

.meteo-container button {
  margin: 1em 0 1em 1em;
  background-color: #a61d25;
  color: #f7fbff;
  border: none;
  border-radius: 5px;
  padding: 0.5em 1em;
  font-size: 2em;
  font-family: inherit;
  cursor: pointer;
}

.meteo-links {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 2em;
  width: 70em;
}

.meteo-links a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  width: 10em;
  margin: 1em;
  border-radius: 10px;
  text-decoration: none;
  color: #f7fbff;
}

#map {
  width: 80em;
  height: 50em;
  padding: 20px;
  margin-right: 5em;
  border-radius: 10px;
  /* border: 1em solid #0077cc; */
}

div.disabled {
  opacity: 0.5;
}

.about-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  display: block;
  background: none;
  background-color: #000;
  color: #f7fbff;
  font-size: 0.6em;
  font-family: sans-serif;
  opacity: 0.6;
  width: 10em;
  margin: 1em;
  padding: 0.5em;
  border: none;
  border-radius: 5px;
}

.photographer-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  display: block;
  background: none;
  background-color: #000;
  color: #f7fbff;
  font-size: 0.6em;
  font-family: sans-serif;
  opacity: 0.6;
  width: auto;
  margin: 1em;
  padding: 0.5em;
  border: none;
  border-radius: 5px;
}

.about-btn:hover {
  opacity: 1;
  cursor: pointer;
}

.photographer-btn:hover {
  opacity: 1;
  cursor: pointer;
}

h2 {
  text-shadow: -1px -1px 0 #000000;
  font-family: "Francois One", sans-serif;
  font-size: 1.7em;
  font-weight: normal;
}

h3 {
  text-shadow: -1px -1px 10px #000000;
  font-family: "Francois One", sans-serif;
  font-size: 2em;
  font-weight: normal;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.help {
  display: flex;
  align-items: center;
}

.modal-button {
  display: block;
  background-color: #a61d25;
  color: #ffffff;
  width: fit-content;
  height: 2em;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0.5em;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 0 0 80%;
  line-height: 1em;
}

.modal-button:hover,
.modal-button:active {
  background-color: #f7fbff;
  color: #a61d25;
}

.modal-content {
  background-color: #000000;
  padding: 1em;
  border-radius: 10px;
  text-align: center;
  padding: 3em;
}

.modal-content .header {
  font-size: 3em;
}

.modal-close {
  background-color: #a61d25;
  color: #f7fbff;
  border: none;
  border-radius: 5px;
  padding: 0.4em 1em;
  font-size: 2em;
  font-family: inherit;

  font-size: 1.5em;
  cursor: pointer;
}
.modal-content a {
  color: #a61d25;
  text-decoration: none;
  /* text-shadow: 0px 0px 10px #ffffff; */
}

.modal-content a:hover,
.modal-content a:active {
  color: #f7fbff;
}

.upload-container {
  display: flex;
  margin-bottom: 1em;
  justify-content: end;
  margin-right: 20%;
  align-items: center;
}

.upload-button,
::-webkit-file-upload-button {
  border: none;
  background-color: #a61d25;
  color: #f7fbff;
  border-radius: 10px;
  font-family: inherit;
  font-size: 1em;
  padding: 0.5em 1em;
  margin-right: 1em;
  cursor: pointer;
}

.custom-file-input {
  text-shadow: -1px -1px 10px #000000;
  width: 15em;
  /* font-size: 1em; */
  margin-right: 4em;
}

.upload-button:hover,
::-webkit-file-upload-button:hover,
.upload-button:active,
::-webkit-file-upload-button:active {
  background-color: #f7fbff;
  color: #a61d25;
}

@media (min-width: 768px) {
  .home {
    margin-left: 10%;
  }
  .layout {
    justify-content: start;
  }
}

.about-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-container p {
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.2em;
  font-weight: normal;
  text-shadow: -1px -1px 10px #000000;
}

.about-authors {
  display: flex;
  justify-content: space-around;
}

.about-authors h4 {
  text-shadow: -1px -1px 10px #000000;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.5em;
  font-weight: normal;
  text-align: center;
}

.about-authors img {
  width: 7em;
  height: 7em;
  border-radius: 50%;
  margin: 0 0 1em 0;
}

.about-authors p {
  text-shadow: -1px -1px 10px #000000;
  width: 90%;
  text-align: justify;
}

.author {
  display: flex;
  flex-direction: column;
  justify-content: first baseline;
  text-align: center;
  align-items: center;
  width: 50%;
  padding: 0 2em;
}

.boton-contact-us {
  display: block;
  padding: 0.5em 1em;
  margin: 1em 4em;
  border: none;
  background-color: #0077cc;
  color: #ffffff;
  border-radius: 5px;
}

@media (orientation: portrait) {
  .about-btn {
    font-size: larger;
  }

  .photographer-btn {
    font-size: larger;
  }

  .about-authors {
    display: contents;
  }

  .about-authors h3 {
    font-size: 3em;
  }

  .about-authors h4 {
    font-size: 2em;
  }

  .about-authors p {
    font-size: 1.5em;
  }

  .author {
    display: flex;
    flex-direction: column;
    justify-content: first baseline;
    text-align: center;
    align-items: center;
    /* width: 50%; */
    padding: 2em 2em;
  }

  .boton-contact-us {
    font-size: 1.5em;
  }
}
